.checkout-chain-space .consumption-country-form-group:before,
.checkout-chain-space .consumption-country-form-group:after {
  content: " ";
  display: table;
}
.checkout-chain-space .consumption-country-form-group:after {
  clear: both;
}
ul.checkout-chain {
  list-style: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}
ul.checkout-chain li {
  float: left;
  position: relative;
  display: block;
  white-space: nowrap;
}
ul.checkout-chain li div {
  z-index: 1029;
  padding: 20px 0;
  margin-top: -32px;
  font-size: 17px;
  pointer-events: none;
  outline: none;
  cursor: default;
  position: relative;
  display: block;
  font-weight: 500;
}
@media (max-width: 992px) {
  ul.checkout-chain li div {
    font-size: 14px;
  }
}
ul.checkout-chain li.active {
  color: #008cba;
}
ul.checkout-chain li.col-sm-2-4 {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
ul.checkout-chain li:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
ul.checkout-chain li:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
ul.checkout-chain:before {
  display: table;
  content: " ";
}
ul.checkout-chain:after {
  clear: both;
  display: table;
  content: " ";
}
.cart-button {
  text-align: center;
}
.cart-button i {
  font-size: 35px;
  padding: 3px;
}
.cart-count {
  margin-top: -36px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 18px;
  text-align: center;
}
.shopping-cart-button {
  min-height: 71px;
}
.shopping-cart-button .dropdown-bar {
  min-height: 39px;
  margin: 11px;
  margin-right: -10px;
  padding-right: 11px;
  text-align: right;
}
.shopping-cart-button .dropdown-menu {
  left: auto;
  right: 0;
}
.address-return-button {
  width: 100%;
}
.add-to-cart-promotion-choice {
  clear: both;
}
.username-label,
.password-label {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1200px) {
  .username-label,
  .password-label {
    float: left;
    width: 25%;
  }
}
.username-field,
.password-field {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1200px) {
  .username-field,
  .password-field {
    float: left;
    width: 75%;
  }
}
.remember-me-col,
.login-buttons-col {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 1200px) {
  .remember-me-col,
  .login-buttons-col {
    margin-left: 25%;
  }
}
@media (min-width: 1200px) {
  .remember-me-col,
  .login-buttons-col {
    float: left;
    width: 75%;
  }
}
.order-review-email-lists span.email-list-narrative {
  font-weight: normal;
  font-size: 85%;
}
.member-discount-price {
  display: flex;
}
.member-discount-price .member-discount-text {
  flex-grow: 1;
  margin: auto;
  margin-right: 5px;
}
.member-discount-price .member-price {
  flex-grow: 0;
}
.checkout-chain-space .select-consumption-country {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .checkout-chain-space .select-consumption-country {
    float: left;
    width: 33.33333333%;
  }
}
.checkout-chain-space .select-consumption-country .form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkout-chain-space .confirm-consumption-country {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .checkout-chain-space .confirm-consumption-country {
    margin-left: 75%;
  }
}
@media (min-width: 768px) {
  .checkout-chain-space .confirm-consumption-country {
    float: left;
    width: 25%;
  }
}
.checkout-chain-space .consumption-country-form-group {
  margin-left: -15px;
  margin-right: -15px;
}
.review-address-block {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
