﻿@import (reference) "../../../Cider.Framework/Assets/Less/bootstrap-references.less";

ul.checkout-chain {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;

    li {
        float: left;
        position: relative;
        display: block;
        white-space:nowrap;

        div {
            z-index:1029;
            padding: 20px 0;
            margin-top: -32px;
            font-size: 17px;
            pointer-events: none;
            outline: none;
            cursor: default;
            position: relative;
            display: block;
            font-weight: 500;
        }

        @media (max-width: @screen-md-min) {
            div {
                font-size: 14px;
            }
        }
    }

    li.active{
        color:#008cba;
    }

    li.col-sm-2-4 {
        width: 20%;
        padding-left:15px;
        padding-right:15px;
    }

    li:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    li:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    &:before {
        display: table;
        content: " ";
    }

    &:after {
        clear: both;
        display: table;
        content: " ";
    }
}

.cart-button {
    text-align:center;
    i {
        font-size: 35px;
        padding: 3px;
    }
}


.cart-count{
    margin-top: -36px;
    margin-bottom: 10px;
    margin-left:5px;
    font-size: 18px;
    text-align:center;
}

.shopping-cart-button {
    min-height: 71px;

    .dropdown-bar {
        min-height: 39px;
        margin: 11px;
        margin-right: -10px;
        padding-right: 11px;
        text-align: right;
    }

    .dropdown-menu {
        left: auto;
        right: 0;
    }
}

.address-return-button{
    width:100%;
}

.add-to-cart-promotion-choice {
    clear:both;
}
.username-label, .password-label{
    .make-lg-column(3);
}

.username-field, .password-field{
    .make-lg-column(9);
}

.remember-me-col, .login-buttons-col{
    .make-lg-column-offset(3);
    .make-lg-column(9);
}

.order-review-email-lists {
    span.email-list-narrative {
        font-weight: normal;
        font-size: 85%;
    }
}

.member-discount-price {
    display:flex;
    .member-discount-text {
        flex-grow: 1;
        margin:auto;
        margin-right:5px;
    }
    .member-price {
        flex-grow: 0;
    }
}

.checkout-chain-space {
    .select-consumption-country {
        .make-sm-column(4);

        .form-control {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .confirm-consumption-country {
        .make-sm-column-offset(9);
        .make-sm-column(3);
    }

    .consumption-country-form-group {
        .make-row();
    }
}

.review-address-block {
    .text-overflow();
}